<template>
  <div class="container">
    <div class="large-12 medium-12 small-12 cell">
      <label>File
        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
      </label>
      <button v-on:click="submitFile()">Отправить</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['model', 'component'],
  data () {
    return {
      file: '',
      local: this.model
    }
  },
  watch: {
    local () {
      this.model = this.local
    },
    model () {
      this.local = this.model
    }
  },
  methods: {
    submitFile () {
      let me = this
      let formData = new FormData()
      formData.append('file', this.file)
      let obj = `{"constant_fields": [{"field_id":180,"value":${this.local},"is_key":true}]}`
      formData.append('payload', obj)
      this.$http.post(`${this.$config.api}/etleditor/import/39`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then((response) => {
        this.$http.post(`${this.$config.api}/registryservice/plugins/execute/CalculateInvestmentExpensesBudgetIndicatorsAfterImportForInvestmentRequestAsyncCommand`,
          { 'investmentExpensesBudgetIds': JSON.parse(response.data.result)['imported_ids'], 'investmentRequestId': this.model, 'initiatorUserId': 1, 'async': 'true' },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        console.log('SUCCESS!!')
        me.component.loadData()
      }).catch(function (error) {
        console.log(error)
        console.log('FAILURE!!')
      })
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
    }
  }
}
</script>
